<template>
  <div class="details">
    <div>
      <div>
        <div>
          <div>Collection Details</div>
          <div>Name:</div>
          <div>{{data.collectionData.name}}</div>
          <div>Description:</div>
          <div>{{data.collectionData.info}}</div>
          <div>Created at:</div>
          <div>{{getFormattedTime(data.collectionData.createdAt)}}</div>
          <div>Updated at:</div>
          <div>{{getFormattedTime(data.collectionData.updatedAt)}}</div>
        </div>
        <div>
          <img :src="data.collectionData.image == '' ? data.logo : imageServerUrl + data.collectionData.image" height="200"/>
        </div>
      </div>
      <div>Tests Included</div>
      <list origin="tests" :listItems="data.collectionData.tests" :class="!isMobileView ?  'pt-30 pb-5 desktopOffset testList' : 'pl-30 pt-20 mb-10'"></list>
      <div @click="navigatetoList">Back</div>
      <div @click="navigatetoEdit">Edit</div>
    </div>
  </div>
</template>
<script setup>
import {List} from '@/components'
import { reactive, onBeforeMount, inject } from 'vue'
import { get } from "@/lib/api";
import router from "@/lib/router";
import { useRoute } from "vue-router";

const route = useRoute();
const imageServerUrl = inject('imageServerUrl')
const data = reactive({
    collectionData: '',
    logo: ''
})

function navigatetoList(){
    router.push({path: '/collections'})
}

function navigatetoEdit(){
    router.push({path: `/collections/update/${route.params.id}`})
}

const getFormattedTime = (date) => {
    if(data.collectionData){
        const d = new Date(date);
        const time =  d.toLocaleString('en-US',{hour:'2-digit', minute:'2-digit', hour12:true});
        let stime = time.split(':');
        return `${("0" + d.getDate()).slice(-2)}/${("0" + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()} ${stime[0]+':'+stime[1]}`
    }
}
onBeforeMount(async () => {
    try {
        let res = await get(`/users/me/benchmarks/${route.params.id}`, true)
        data.collectionData = res.data
    }
    catch(error) {
        console.error("dataFetchError : " + error)
    }
})
</script>